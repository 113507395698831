<div id="container">

    <div class="flex justify-content-between gap1">

        <app-side-nav></app-side-nav>

        <div class="content">
            <div class="title">
                {{ 'APPOINTMENTS.PAGES.BOOKING_PAGE.TITLE.Header' | translate }}
            </div>
    
            <div class="flex justify-content-between align-items-start w-100">
    
                <div class="flex justify-content-start align-items-start gap05">
    
                    <img [src]="doctorData?.image || 'assets/images/person.png'" alt="photo" class="photo">
    
                    <div class="flex flex-col align-items-start justify-content-between gap05">

                        <div class="font-18 font-semibold">
                            {{ doctorData?.name }}
                        </div>
    
                        <!-- rating -->
                        <div class="flex"
                            *ngIf="doctorData?.rating">
    
                            <span role="img" class="rating-icon rating-icon-default rating-icon-readonly">
                                <svg>
                                    <path
                                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                    </path>
                                </svg>
                            </span>
    
                            <span role="img" class="rating-icon rating-icon-readonly"
                                [ngClass]="{ 'rating-icon-default': doctorData.rating > 1 }">
                                <span role="img" class="rating-icon rating-icon-readonly">
                                    <svg>
                                        <path
                                            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                        </path>
                                    </svg>
                                </span>
                            </span>
    
                            <span role="img" class="rating-icon rating-icon-readonly"
                                [ngClass]="{ 'rating-icon-default': doctorData.rating > 2 }">
                                <span role="img" class="rating-icon rating-icon-readonly">
                                    <svg>
                                        <path
                                            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                        </path>
                                    </svg>
                                </span>
                            </span>
    
                            <span role="img" class="rating-icon rating-icon-readonly"
                                [ngClass]="{ 'rating-icon-default': doctorData.rating > 3 }">
                                <span role="img" class="rating-icon rating-icon-readonly">
                                    <svg>
                                        <path
                                            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                        </path>
                                    </svg>
                                </span>
                            </span>
    
                            <span role="img" class="rating-icon rating-icon-readonly"
                                [ngClass]="{ 'rating-icon-default': doctorData.rating > 4 }">
                                <span role="img" class="rating-icon rating-icon-readonly">
                                    <svg>
                                        <path
                                            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                                        </path>
                                    </svg>
                                </span>
                            </span>
    
                        </div>
    
                        <!-- specialties -->
                        <div class="flex gap05 flex-1" *ngIf="doctorData?.specialties && doctorData.specialties.length > 0">
                            <span class="font-16 font-regular"  *ngFor="let specialty of doctorData?.specialties">
                                {{ specialty }}
                            </span>
                        </div>
    
                    </div>
    
                </div>
    
                <div class="flex gap05 align-items-center" style="margin-left: auto;">
                    <span class="font-semibold font-16">
                        {{ 'APPOINTMENTS.PAGES.BOOKING_PAGE.LABELS.Price' | translate }}:
                    </span>
                    <span class="font-regular font-16 blue-txt">
                        {{ doctorData?.price }}
                    </span>
                    <span class="font-regular font-16 blue-txt">
                        {{ 'APPOINTMENTS.PAGES.BOOKING_PAGE.LABELS.Credits' | translate }}
                    </span>
                </div>
    
            </div>
    
            <div class="flex justify-content-start gap05 w-100" style="min-height: 360px;">
                <mat-calendar [minDate]="minDate" (selectedChange)="onDateChange($event)" [(selected)]="selectedDate"
                    class="calendar">
                </mat-calendar>
    
                <div class="flex-col gap05 w-50">
    
                    <div class="font-semibold font-18">
                        {{ 'APPOINTMENTS.PAGES.BOOKING_PAGE.LABELS.Available_hours' | translate }}
                    </div>
    
                    <div class="flex flex-wrap justify-content-start align-items-start gap05" style="height: max-content;">
    
                        <div class="available-hour pointer" *ngFor="let ts of availableHours"
                            [ngClass]="{'hour-selected': selectedHour == ts}" (click)="selectedHour = ts">
                            {{ ts | date:'HH:mm' }}
                        </div>
                    </div>
    
                </div>
    
            </div>
    
            <div class="flex flex-wrap gap2 justify-content-start align-items-start w-100">
    
                <div class="flex-col gap05">
    
                    <div class="font-semibold font-18">
                        {{ 'APPOINTMENTS.PAGES.BOOKING_PAGE.LABELS.Concerns' | translate }}
                    </div>
    
                    <textarea name="note" id="note" maxlength="100" cols="80" rows="6" class="font-regular font-16 note"
                        style="resize:none" [(ngModel)]="patientNote" #note="ngModel">
    
                    </textarea>
    
                </div>
    
                <div class="flex-col align-items-center gap05">
    
                    <div class="font-semibold font-18">
                        {{ 'APPOINTMENTS.PAGES.BOOKING_PAGE.LABELS.Files' | translate }}
                    </div>
    
                    <div class="flex flex-wrap gap05 justify-content-start">
    
                        <ng-container  *ngFor="let file of filePreviews">
    
                            <div class="img-wrapper" *ngIf="fileIsImage(file.mimeType)">
                                <img [src]="file.src" alt="" class="w-100 h-100" (click)="viewImage(file.src)">
                                <span (click)="removeFile(file)" class="remove-img">
                                    <mat-icon>close</mat-icon>
                                </span>
                            </div>
    
                            <div class="img-wrapper is-file" *ngIf="!fileIsImage(file.mimeType)">
                                <div class="flex flex-col justify-content-center align-items-center gap05 w-100 h-100">
                                    <mat-icon style="font-size: 30px;color: #3498db">insert_drive_file</mat-icon>
                                    <span>
                                        {{ docType(file.mimeType) }}
                                    </span>
                                    <span>
                                        {{ file?.fileContent?.name }}
                                    </span>
                                </div>
                                <span (click)="removeFile(file)" class="remove-img">
                                    <mat-icon>close</mat-icon>
                                </span>
                            </div>
    
                        </ng-container>
    
                        <label for="f-input" class="upload-wrapper">
                            <input type="file" id="f-input" multiple (change)="onFileSelected($event)" style="display: none"
                                #bookingFileInput>
                            <mat-icon class="file-upload pointer">add</mat-icon>
                            <!-- &#43; -->
                        </label>
                    </div>
    
                </div>
    
    
            </div>
    
            <button class="blue-button margin-auto mt2 mb2" style="width: max-content;"
                [ngClass]="{'disabled-button': !enableButton() || processing}" (click)="openChoosePaymentOptionDialog()"
                [disabled]="!enableButton() || processing">
                {{ 'APPOINTMENTS.PAGES.DOCTOR_PAGE.BUTTONS.Book_consultation' | translate }}
            </button>
    
        </div>

    </div>

</div>
import { Component, OnInit, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-add-emergency-contact-dialog',
  templateUrl: './add-emergency-contact-dialog.component.html',
  styleUrls: ['./add-emergency-contact-dialog.component.scss']
})
export class AddEmergencyContactDialogComponent implements OnInit {
  visitId: any;
  contactId: any;
  contactName: string;
  contactPhone: string;
  isEdit: boolean = false;
  submitted: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddEmergencyContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      contactName: string, contactPhone: string, visitId: any, contactId: any, isEdit: boolean
    },
    private apiService: ApiService) {
    this.visitId = this.data.visitId;
    this.contactId = this.data.contactId;
    this.contactName = this.data.contactName;
    this.contactPhone = this.data.contactPhone;
    this.isEdit = this.data.isEdit
  }

  ngOnInit(): void {
  }

  onSubmit(f: NgForm) {
    this.submitted = true;
    if (f.invalid) {
      return;
    }

    if (!this.isEdit) {
      this.apiService.addEmergencyContact(this.visitId, this.contactName, this.contactPhone).subscribe({
        next: (response: any) => {
          if (response) {
            this.dialogRef.close(response.data);
          }
        },
        error: (err: any) => console.error(err),
        complete: () => {}
      });
    } else {
      this.apiService.editEmergencyContact(this.visitId, this.contactId, this.contactName, this.contactPhone).subscribe({
        next: (response: any) => {
          if (response) {
            this.dialogRef.close(response.data);
          }
        },
        error: (err: any) => console.error(err),
        complete: () => {}
      })
    }

  }

  close() {
    this.dialogRef.close();
  }
}

import { LocalStorageService } from 'ngx-webstorage';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	protected apiUrl = environment.protocol + environment.apiUrl + "/";
	protected apiVer2Url = environment.protocol + environment.apiVer2URL + "/";
	private authUrl = this.apiVer2Url + 'auth/';
	private loginUrl = this.apiVer2Url + 'auth/login';
	private userUrl = this.apiVer2Url + 'user/';
	private paymentUrl = this.apiUrl + 'payment/';
	private paypalUrl = this.apiUrl + 'paypal/';
	constructor(protected http: HttpClient, private localStorage: LocalStorageService) { }

	public getLoginAut(data: any): Observable<any> {
		return this.http.post(this.loginUrl, data);
	}

	public doLogoutAut(value: any): Observable<any> {
		return this.http.post(this.authUrl + 'logout', value);
	}

	public registerUser(data: any): Observable<any> {
		return this.http.post(this.userUrl + 'create', data);
	}

	public updateUser(data: any): Observable<any> {
		return this.http.post(this.userUrl + 'update', data);
	}

	public getLoggedUserID() {
		return this.localStorage.retrieve('loggedUser').id;
	}

	public getLoggedUser() {
		return this.localStorage.retrieve('loggedUser');
	}

	public getUserData(userID: number): Observable<any> {
		return this.http.get(this.userUrl + 'view/' + userID + "?expand=userDoctors");
	}

	public refreshToken(data: any): Observable<any> {
		return this.http.post(this.userUrl + 'refresh-token', data);
	}

	public getDoctors(): Observable<any> {
		return this.http.get(this.userUrl + 'get-doctors');
	}

	public getUsers(data: any): Observable<any> {
		return this.http.post(this.userUrl + 'list', data, { observe: 'response' as 'response' });
	}

	public deleteUser(id: Number): Observable<any> {
		return this.http.delete(this.userUrl + id);
	}

	public verifyEmail(token: any): Observable<any> {
		return this.http.post(this.userUrl + 'verify-email', token);
	}

	public requestPasswordReset(data: any): Observable<any> {
		return this.http.post(this.userUrl + 'request-password-reset', data);
	}

	public checkResetToken(data: any): Observable<any> {
		return this.http.post(this.userUrl + 'check-reset-token', data);
	}

	public resetPassword(data: any): Observable<any> {
		return this.http.post(this.userUrl + 'reset-password', data);
	}

	public getPaymentData(data: any): Observable<any> {
		return this.http.post(this.paymentUrl + 'payment-data', data);
	}

	public sendLinkReport(data: any): Observable<any> {
		return this.http.post(this.paymentUrl + 'send-link-report', data);
	}

	public getPaymentList(data: any): Observable<any> {
		return this.http.post(this.paymentUrl + 'list?expand=paymentItems', data, { observe: 'response' as 'response' });
	}

	public createPayment(data: any): Observable<any> {
		return this.http.post(this.paypalUrl + 'create-payment', data);
	}

	public capturePayment(data: any): Observable<any> {
		return this.http.post(this.paypalUrl + 'capture-payment', data);
	}

	public confirmToken(data: any): Observable<any> {
		return this.http.post(this.authUrl + 'confirm-token', data);
	}

	public listEmergencyContacts(visitId: any): Observable<any> {
		return this.http.get(this.apiVer2Url + `visit/${visitId}/list-patient-contacts`);
	}

	public addEmergencyContact(visitId: any, name: any, phone: any, email: any = ''): Observable<any> {
		let body: any = {
			"PatientContactType": "RELATIVE",
			"PatientContactName": name,
			"PatientContactPhone": phone,
			"PatientContactEmail": email
		}

		return this.http.post(this.apiVer2Url + `visit/${visitId}/save-patient-contact` + '', body);
	}

	public editEmergencyContact(visitId: any, contactId: any, name: any, phone:any, email: any = ''): Observable<any> {
		let body: any = {
			PatientContactID: contactId,
			PatientContactType: "RELATIVE",
			PatientContactName: name,
			PatientContactPhone: phone,
			PatientContactEmail: email
		}

		return this.http.post(this.apiVer2Url + `visit/${visitId}/save-patient-contact` + '', body);
	}

	public deleteEmergencyContact(visitId: any, contactId: any): Observable<any> {
		return this.http.delete(this.apiVer2Url + `visit/${visitId}/delete-patient-contact/${contactId}`);
	}
}

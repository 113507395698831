<div class="chat-button" (click)="checkStatusAndOpenChat();">
    <mat-icon style="font-size: 30px;line-height: 60px;">chat_bubble_outline</mat-icon>
    <div class="messages-badge" [title]="'unread messages'" *ngIf="unreadCount > 0 && socketIsConnected">
        {{ unreadCount }}
    </div>
    <div class="messages-badge" [title]="'no internet connection'" *ngIf="!socketIsConnected">
        <mat-icon style="font-size: 20px; line-height: 26px;">wifi_off</mat-icon>
    </div>
</div>
<div class="audio">
    <audio loop #ringTone>
        <source src="assets/audio/telephone_ring_sound.mp3">
        <source src="app/chat/sounds/telephone_ring_sound.mp3">
    </audio>
    <audio #hangupTone>
        <source src="assets/audio/telephone_hangup_sound.mp3">
        <source src="app/chat/sounds/telephone_hangup_sound.mp3">
    </audio>
</div>
<div class="chat-window">
    <div class="window-header">
        <div class="logo">
            <span style="height: 30px;">
                <img src="assets/images/myki-care-logo.svg" alt="logo">
            </span>
            <span>
                {{ data?.chatTitle }}
            </span>
        </div>
        <div class="actions">
            <span class="pointer" (click)="initiateCall()" *ngIf="socketIsConnected">
                <mat-icon style="color: #59AAF8">videocam</mat-icon>
            </span>
            <!-- <span class="pointer">
                <mat-icon mat-dialog-close="minimize">minimize</mat-icon>
            </span> -->
            <span class="pointer">
                <mat-icon mat-dialog-close="close">close</mat-icon>
            </span>
        </div>
    </div>
    <div class="window-body" #messagesContainer (scroll)="onScroll()">
        <div class="visible-messages" #visibleMessages>
            <ng-container *ngFor="let message of chatHistory | keyvalue">
                <div class="message-box" [id]="'message-' + message.key" *ngIf="!message?.value?.video">

                    <div class="sender" *ngIf="message?.value?.isResponse">
                        <div class="responder-avatar" [ngClass]="{'initials': !message?.value?.avatarUrl}"
                            *ngIf="message?.value?.isResponse" [title]="message?.value?.senderName || ''">

                            <img [src]="responderAvatar" alt="avatar" *ngIf="message?.value?.avatarUrl">

                            <div *ngIf="!message?.value?.avatarUrl">
                                {{ showInitials(message?.value?.senderName) }}
                            </div>

                        </div>

                        {{ message?.value?.senderName || '' }}
                    </div>

                    <div class="message"
                        [ngClass]="{response: message?.value?.isResponse, user: !message?.value?.isResponse}"
                        *ngIf="message?.value?.text">


                        <div class="message-status" *ngIf="message?.value.isResponse">
                            <span class="unread" *ngIf="isUnreadMessage(message?.value)">
                            </span>
                        </div>

                        <div class="message-content" [ngClass]="{user: !message?.value?.isResponse}">
                            <span>{{ message?.value?.text || '' }}</span>
                        </div>

                        <div class="message-status" *ngIf="!message?.value.isResponse">

                            <span class="seen" *ngIf="isSeen(message?.value)">
                                <span class="inner-circle"></span>
                            </span>

                            <span class="unseen" *ngIf="!isSeen(message?.value)"></span>

                        </div>

                    </div>

                    <div class="chat-images" *ngIf="message.value.files" 
                        [ngClass]="{user: !message?.value?.isResponse, response: message?.value?.isResponse}" style="margin-top: 35px">
                        <ng-container *ngFor="let file of message.value.files">
                            <img class="image-preview" [src]="assetsDir + file.thumbnail" alt="preview"
                                *ngIf="file.thumbnail && fileIsImage(file.mimeType)" (click)="viewImage(file.fileUrl)">

                            <div class="doc-preview" *ngIf="!fileIsImage(file.mimeType)" (click)="openDocument(file)">
                                <div class="doc-icon-wrapper">
                                    <mat-icon style="font-size: 30px; color: #3498db">insert_drive_file</mat-icon>
                                </div>
                                <div class="doc-info">
                                    <div class="doc-name">
                                        {{ file?.fileName }}
                                    </div>
                                    <div class="doc-type">
                                        {{ docType(file.mimeType) }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div class="message-time" [ngClass]="{user: !message?.value?.isResponse}">
                        {{ isPastMessage(message?.value?.time)
                        ? (message?.value?.time | date:'EE dd MMM yyyy HH:mm')
                        : (message?.value?.time | date:'HH:mm')
                        }}
                    </div>
                </div>
                <!-- video call messages -->
                <div class="message-box" [id]="'message-' + message.key" *ngIf="message?.value?.video">

                    <div class="sender" *ngIf="message?.value?.isResponse">
                        <div class="responder-avatar" [ngClass]="{'initials': !message?.value?.avatarUrl}"
                            *ngIf="message?.value?.isResponse" [title]="message?.value?.senderName || ''">

                            <img [src]="responderAvatar" alt="avatar" *ngIf="message?.value?.avatarUrl">

                            <div *ngIf="!message?.value?.avatarUrl">
                                {{ showInitials(message?.value?.senderName) }}
                            </div>

                        </div>

                        {{ message?.value?.senderName || '' }}
                    </div>

                    <div class="message"
                        [ngClass]="{response: message?.value?.isResponse, user: !message?.value?.isResponse}">

                        <div class="message-status" *ngIf="message?.value.isResponse">
                            <span class="unread" *ngIf="isUnreadMessage(message?.value)">
                            </span>
                        </div>

                        <div class="message-content"
                            [ngClass]="{ 'missed-call': message?.value?.video?.type != 1 || message?.value?.video?.type != 2 }">

                            <div class="call-container">

                                <div>
                                    <mat-icon>videocam</mat-icon>
                                </div>

                                <div class="call-text">
                                    <span *ngIf="message?.value?.video?.type != 3">
                                        {{ 'CHAT.TITLES.Video_call' | translate }}
                                    </span>
                                    <span *ngIf="message?.value?.video?.type == 1">
                                        {{ 'CHAT.TEXT.Call_initiated' | translate }}
                                    </span>
                                    <span *ngIf="message?.value?.video?.type == 2">
                                        {{ 'CHAT.TEXT.Call_duration' | translate }}
                                        {{ callDuration(message?.value?.video?.duration) }}
                                    </span>
                                    <span *ngIf="message?.value?.video?.type == 3" style="margin-bottom: 5px;">
                                        {{ 'CHAT.TEXT.Call_missed' | translate }}
                                    </span>

                                    <span *ngIf="message?.value?.video?.type == 4" style="margin-bottom: 5px;">
                                        {{ 'CHAT.TEXT.Call_canceled' | translate }}
                                    </span>

                                    <span *ngIf="message?.value?.video?.type == 5" style="margin-bottom: 5px;">
                                        {{ 'CHAT.TEXT.Call_no_answer' | translate }}
                                    </span>

                                    <span *ngIf="message?.value?.video?.type == 6" style="margin-bottom: 5px;">
                                        {{ 'CHAT.TEXT.Call_rejected' | translate }}
                                    </span>
                                </div>

                            </div>

                        </div>

                        <div class="message-status" *ngIf="!message?.value.isResponse">

                            <span class="seen" *ngIf="isSeen(message?.value)">
                                <span class="inner-circle"></span>
                            </span>

                            <span class="unseen" *ngIf="!isSeen(message?.value)"></span>

                        </div>

                    </div>

                    <div class="message-time" [ngClass]="{user: !message?.value?.isResponse}">
                        {{ isPastMessage(message?.value?.time)
                        ? (message?.value?.time | date:'EE dd MMM yyyy HH:mm')
                        : (message?.value?.time | date:'HH:mm')
                        }}
                    </div>
                </div>
            </ng-container>
            <div *ngIf="!socketIsConnected" style="text-align: center; color: red; text-transform:uppercase;">
                {{ 'CHAT.TEXT.Socket_disconnected' | translate }}
            </div>
        </div>
    </div>
    <div class="window-footer">
        <label for="file-input" class="chat-actions">
            <input type="file" id="file-input" multiple (change)="onFileSelected($event)" style="display: none"
                #fileInput>
            <mat-icon>attach_file</mat-icon>
            <!-- &#43; -->
        </label>
        <div class="input-box">
            <input class="chat-input" placeholder="Ask a question…" type="text" [(ngModel)]="userInput"
                (keyup.enter)="sendMessage()">
            <div class="btn-send" (click)="sendMessage()" [ngClass]="{disabled: !allFilesAreUploaded || !socketIsConnected}"
                [title]="!allFilesAreUploaded ? 'Cannot send while files are still uploading' : ''">
                <mat-icon>send</mat-icon>
            </div>
        </div>
    </div>
    <div class="preview-box" *ngIf="imagePreviews.length > 0">
        <div class="image-container" *ngFor="let file of imagePreviews">

            <div class="image-preview-item" *ngIf="fileIsImage(file.mimeType)">
                <img class="image-preview" [src]="file.src" alt="preview"
                    [ngClass]="{ 'is-uploading': fileIsUploading(file.fileContent) }">
                <div class="image-preview-overlay">
                    <div *ngIf="fileIsUploading(file.fileContent)" class="progress-wheel"></div>
                    <div class="remove-button" (click)="removeFile(file)" *ngIf="!fileIsUploading(file.fileContent)">
                        x
                    </div>
                </div>
            </div>

            <div class="image-preview-item" *ngIf="!fileIsImage(file.mimeType)">
                <div class="doc-preview">
                    <div class="doc-icon-wrapper">
                        <mat-icon style="font-size: 30px; color: #3498db">insert_drive_file</mat-icon>
                    </div>
                    <div class="doc-info">
                        <div class="doc-name">
                            {{ file?.fileContent?.name || file?.chatObj?.fileName }}
                        </div>
                        <div class="doc-type">
                            {{ docType(file.mimeType) }}
                        </div>
                    </div>
                </div>
                <div class="image-preview-overlay">
                    <div *ngIf="fileIsUploading(file.fileContent)" class="progress-wheel"></div>
                    <div class="remove-button" (click)="removeFile(file)" *ngIf="!fileIsUploading(file.fileContent)">
                        x
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HOME_NAV_ITEMS } from '../../home/classes/constants';
import { SidebarService } from '../../components/side-nav/sidebar.service'
import { Subscription } from 'rxjs/internal/Subscription';
import { SocketIoService, SocketStates } from '../../../app/services/socket-io.service';
import { add, differenceInDays, isAfter, isBefore, sub } from 'date-fns';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ChartsService } from '../../services/charts.service';
import { DatePipe } from '@angular/common';


const NAV_MENU_TITLES: any = {
  [HOME_NAV_ITEMS['calendar']]: 'Calendar',
  [HOME_NAV_ITEMS['plan care']]: "Plan care",
  [HOME_NAV_ITEMS.charts]: "Charts",
  [HOME_NAV_ITEMS.devices]: "Devices",
  [HOME_NAV_ITEMS.medication]: "Medication",
  [HOME_NAV_ITEMS.questionnaires]: "Questionnaires",
  [HOME_NAV_ITEMS.specialists]: "Specialists",
  [HOME_NAV_ITEMS['appointments-list']]: "Appointments",
  [HOME_NAV_ITEMS['calls-history']]: "Calls History",
  [HOME_NAV_ITEMS['reports-and-payments']]: "Reports & Payments",
  [HOME_NAV_ITEMS['settings']]: "Settings",
  [HOME_NAV_ITEMS['vital signs']]: "Vital signs",
  [HOME_NAV_ITEMS.warnings]: "Warnings",
  [HOME_NAV_ITEMS['request-medical-observation']]: "Request medical observation"
}

const POSTURE_IMGS_SRC: any[] = [
  '',
  'assets/images/standing.svg',
  'assets/images/standing.svg',
  'assets/images/walking.svg',
  'assets/images/running.svg',
  '',
  '',
  'assets/images/lay-supine.svg',
  'assets/images/lay-left.svg',
  'assets/images/lay-right.svg',
  'assets/images/lay-prone.svg',
  'assets/images/lay-back.svg',
];

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
  @Output('vitalsChange') vitalsChange: EventEmitter<any> = new EventEmitter();
  public selectedMenu: undefined | HOME_NAV_ITEMS = HOME_NAV_ITEMS['plan care'];
  public navItems = HOME_NAV_ITEMS;
  public menuTitles: any = NAV_MENU_TITLES;
  public navTitle = NAV_MENU_TITLES[HOME_NAV_ITEMS['plan care']];
  protected subs: Subscription = new Subscription();
  public vitalsData: any;
  public lastVitalsData: any;
  version: any = environment.version;

  constructor(
    private router: Router,
    private sidebarServ: SidebarService,
    private socketIO: SocketIoService,
    private ts: TranslateService,
    private datePipe: DatePipe) { }


  ngOnInit(): void {
    this.subs.add(
      this.sidebarServ.currRoute$.subscribe((currRoute: any) => {
        this.selectedMenu = currRoute;
        this.navTitle = this.menuTitles[currRoute];
      })
    );

    this.subs.add(this.socketIO.socketState$.subscribe((state: SocketStates) => {
      if (state == SocketStates.open) {
        this.initSocket();
      }
    }));

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (this.socketIO.isConnected()) {
      this.socketIO.unsubscribeForVitalsData();
    }
    this.removeSocketEventListeners();
  }

  onMenuSelect(menu: HOME_NAV_ITEMS) {
    this.selectedMenu = menu;
    this.navTitle = this.menuTitles[menu];
    this.sidebarServ.setRoute(menu);
    switch (menu) {
      case this.navItems['questionnaires']:
        this.router.navigate(['questionnaires']);
        break;
      case this.navItems['specialists']:
        this.router.navigate(['appointments']);
        break;
      case this.navItems['appointments-list']:
        this.router.navigate(['appointments/consultations']);
        break;
      case this.navItems['calls-history']:
        this.router.navigate(['appointments/call-history']);
        break;
      case this.navItems['subscription-plans']:
        this.router.navigate(['billing/subscription-plans']);
        break;
      case this.navItems['buy-credits']:
        this.router.navigate(['billing/buy-credits']);
        break;
      case this.navItems['request-medical-observation']:
        this.router.navigate(['billing/request-medical-observation']);
        break;
      default:
        this.router.navigate(['']);
        break;
    }
  }

  initSocket() {
    this.socketIO.subscribeForVitalsData();
    this.subscribeForLastVitals();
    this.subscribeForVitalsData();
  }

  subscribeForVitalsData() {
    this.socketIO.getVitals((vitals: any) => {
      this.vitalsData = vitals;
      this.vitalsChange.emit();
    });
  }

  subscribeForLastVitals() {
    this.socketIO.getLastVitals((vitals: any) => {
      this.lastVitalsData = vitals;
      this.vitalsChange.emit();
    });
  }

  get vitalsDate() {
    if (!this.vitalsData?.ts && !this.lastVitalsData?.ts) {
      return '';
    }

    if (this.vitalsData?.ts && this.lastVitalsData?.ts) {
      let live = new Date(this.vitalsData.ts * 1000);
      let last = new Date(this.lastVitalsData.ts * 1000);
      return isAfter(live, last) ? live : last;
    }

    return new Date(this.lastVitalsData?.ts * 1000);
  }

  getVitalTime(vitalSign: any) {
    if (!vitalSign || !this.lastVitalsData) {
      return;
    }
    switch (vitalSign) {
      case 'o2':
        if (this.lastVitalsData.o2_ts) {
          return new Date(this.lastVitalsData.o2_ts * 1000);
        }
        return '';
      default:
        return '';
    }
  }

  get now() {
    return new Date();
  }

  removeSocketEventListeners() {
    this.socketIO.removeEventListeners('last-vital-params');
    this.socketIO.removeEventListeners('vital-params');
  }

  getPositionLabel(pos: any): string {
    let posture = ChartsService.POSTURE[pos];
    let label: string = '';
    this.ts.get(`POSTURES.${posture}`).subscribe((text: any) => {
      label = text;
    });
    return label;
  }

  getPostureImageSrc(pos: any): string {
    return POSTURE_IMGS_SRC[pos];
  }

  showVitalLastMeasurementTime(timestamp: any): any {
    if (!this.lastVitalsData?.ts || !timestamp) {
      return undefined;
    }

    let lastRecorded = new Date(this.lastVitalsData.ts * 1000);
    let vitalLastRecorded = new Date(timestamp * 1000);
    if (isBefore(vitalLastRecorded, sub(lastRecorded, { days: 1 }))) {
      let difference = differenceInDays(lastRecorded, vitalLastRecorded);
      let text: any;
      if (difference > 1) {
        this.ts.get('SIDENAV.LABELS.Days_ago', {days: difference}).subscribe(
          (translation: any) => {
            if (translation) {
              text = translation;
            }
          }
        );
      } else {
        this.ts.get('SIDENAV.LABELS.Day_ago').subscribe(
          (translation: any) => {
            if (translation) {
              text = translation;
            }
          }
        );
      }
      return text;
    }

    return this.datePipe.transform(timestamp * 1000, 'HH:mm:ss');
  }
}

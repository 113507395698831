export class EditProfileModel {
  constructor(
    public first_name: any = '',
    public last_name: any = '',
    public birth_date: any = '',
    public gender: any = '',
    public email: any = '',
    public phoneNumber: any = '',
    public weight: any = '',
    public height: any = ''
  ) { }
}

export class ChangePasswordModel {
  constructor(
    public oldPassword: any = '',
    public newPassword: any = '',
    public repeatPassword: any = ''
  ) { }
}

export class EmergencyContactModel {
  constructor(
    public emergencyContacts: any = []
  ) { }
}
<div class="dialog-wrapper">
    <div class="dialog-header">
        <h2>
            {{ 'LAYOUT.PROFILE.EMERGENCY_CONTACTS.LABELS.Delete_emergency_contact' | translate }}
        </h2>
        <div class="close" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>

    <div class="dialog-body">
        {{ 'LAYOUT.PROFILE.EMERGENCY_CONTACTS.TEXTS.Delete_emergency_contact_confirmation' | translate:{contactName: contactName, contactPhone: contactPhone} }}
    </div>

    <div class="dialog-footer">
        <div class="flex justify-content-center gap05">
            <button style="background-color: #c1c1c1;" (click)="close()">
                {{ 'LAYOUT.PROFILE.EMERGENCY_CONTACTS.BUTTONS.Cancel' | translate }}
            </button>
            <button style="background-color: red;" (click)="deleteContact()">
                {{ 'LAYOUT.PROFILE.EMERGENCY_CONTACTS.BUTTONS.Delete' | translate }}
            </button>
        </div>
    </div>
</div>
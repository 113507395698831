export enum VitalTypes {
    HEART_RATE = 'hr',
    BLOOD_PRESSURE_SYSTOLE = 'ph',
    BLOOD_PRESSURE_DIASTOLE = 'pl',
    RESPIRATION = 'br',
    POSTURE = 'ac',
    SATURATION = 'o2',
    TEMPERATURE = 'tp',
    BLOOD_SUGAR = 'bs',
    STRESS_INDEX = 'stri'
}

export enum VitalAlerts {
    RESPIRATION = 'ALERT_br',
    SATURATION = 'ALERT_o2',
    BLOOD_PRESSURE = 'ALERT_sbp',
    HEART_RATE = 'ALERT_hr',
    TEMPERATURE = 'ALERT_tp',
    BLOOD_SUGAR = 'ALERT_bs',
    STRESS_INDEX = 'ALERT_stri'
}

export type EmergencyContact = {
    PatientContactID: number | string | undefined | null;
    PatientContactType: EmergencyContactType | undefined | null;
    PatientContactName: string | undefined | null;
    PatientContactPhone: string | undefined | null;
    PatientContactEmail: string | undefined | null;
}

export enum EmergencyContactType {
    "GP" = "GP",
    "RELATIVE" = "RELATIVE",
    "DR-SPECIALIST" = "DR-SPECIALIST",
    "NEIGHBORS" = "NEIGHBORS"
}
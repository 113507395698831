import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AppointmentsService, DOCTORS_MOCK_DATA } from '../services/appointments.service';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { FavoriteDoctorsDialogComponent } from './components/favorite-doctors-dialog/favorite-doctors-dialog.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { IDoctor, IDoctorAdditionalInfo } from '../types-and-const/appointment-types';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import { SidebarService } from '../../components/side-nav/sidebar.service';
import { HOME_NAV_ITEMS } from '../../home/classes/constants';

@Component({
  selector: 'app-doctors-list',
  templateUrl: './doctors-list.component.html',
  styleUrls: ['./doctors-list.component.scss']
})
export class DoctorsListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public searchTerm: Subject<string> = new Subject<string>();
  public doctorsData: IDoctor[] = [];
  public filteredData: IDoctor[] = [];
  public medicalSpecialities: Map<number, string> = new Map();
  public doctorsAdditionalData: Map<number, IDoctorAdditionalInfo> = new Map();
  public paginatedItems: IDoctor[] = [];
  public imgDir: string = environment.imageDir;
  private userUid: string = this.localStorage.retrieve('loggedUser')['user_booking_uid'];

  constructor(
    public matDialog: MatDialog,
    private appointmentsService: AppointmentsService,
    private sidebarService: SidebarService,
    private localStorage: LocalStorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.sidebarService.setRoute(HOME_NAV_ITEMS['specialists']);
    this.getDoctorsList();
    // this.getMedicalSpecialities();
    this.searchTerm.pipe(
      debounceTime(300),           // Wait 300ms after each keystroke before considering the term
      distinctUntilChanged(),       // Ignore if the next search term is same as previous
      switchMap((term: string) => {
        let results = this.searchItems(term);
        return [results];
      })
    ).subscribe(results => {
      this.filteredData = results
      this.paginator.firstPage(); // Reset paginator to the first page after filtering
      this.updatePaginatedItems();
    });
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe(() => this.updatePaginatedItems());
  }

  protected getDoctorsList() {
    this.appointmentsService.getAllDoctorsBookingInfo(this.userUid).subscribe(
      {
        next: (result: any) => {
          if (result) {

            this.doctorsData = result;
            this.filteredData = this.doctorsData;
            this.paginatedItems = this.doctorsData;
          }
        },
        error: (err: any) => console.error(err),
        complete: () => { 
          this.updatePaginatedItems();
        }
      }
    );
  }

  public onSearch(text: string) {
    this.searchTerm.next(text);
  }

  searchItems(term: string): any[] {
    term = term.trim();
    if (!term) {
      // if no search term, return all items
      return this.doctorsData;
    }

    return this.doctorsData.filter((item: IDoctor) => {
      
      return item?.name?.toLowerCase().includes(term.toLowerCase()) 
        || item?.longResume?.toLowerCase()?.includes(term.toLowerCase())
        || item?.shortResume?.toLowerCase()?.includes(term.toLowerCase())
        || item?.specialties?.join(' ')?.toLowerCase()?.includes(term.toLowerCase());
    });
  }

  updatePaginatedItems() {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;
    this.paginatedItems = this.filteredData.slice(startIndex, endIndex);
  }

  openDoctorPage(id: number) {
    if (!id) {
      return;
    }

    this.router.navigate(['/appointments/doctor', id]);
  }

  openFavorites() {
    let dialogRef = this.matDialog.open(FavoriteDoctorsDialogComponent, {
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      restoreFocus: false,
      width: '90vw'
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-delete-emergency-contact-dialog',
  templateUrl: './delete-emergency-contact-dialog.component.html',
  styleUrls: ['./delete-emergency-contact-dialog.component.scss']
})
export class DeleteEmergencyContactDialogComponent implements OnInit {
  visitId: any;
  contactId: any;
  contactName: string;
  contactPhone: string;

  constructor(
    private dialogRef: MatDialogRef<DeleteEmergencyContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      contactName: string, contactPhone: string, visitId: any, contactId: any, isEdit: boolean
    },
    private apiService: ApiService) {
    this.visitId = this.data.visitId;
    this.contactId = this.data.contactId;
    this.contactName = this.data.contactName;
    this.contactPhone = this.data.contactPhone;
  }

  ngOnInit(): void {
  }

  deleteContact() {
    this.apiService.deleteEmergencyContact(this.visitId, this.contactId).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.dialogRef.close(response.data);
        } else {
          this.dialogRef.close();
        }
      },
      error: (err: any) => console.error(err),
      complete: () => {}
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}

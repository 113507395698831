<div class="container-register">

	<div class=" imgContainer">
		<img src="/assets/images/doctress.png">
	</div>
	<div class="flexer">
		<app-language-change></app-language-change>
		<div *ngIf="success" class="after-register-screen flexer">
			<div class="after-register-title">{{'REGISTER_SUCCESS.Welcome' | translate}}</div>
			<div class="after-register-message">
				<div>{{'REGISTER_SUCCESS.TEXT.You_have' | translate}}</div>
				<div>{{'REGISTER_SUCCESS.TEXT.Success_registration' | translate}}</div>
			</div>
			<button class="login-register-button">
				<span>{{'BUTTONS.PAYMENT_METHOD' | translate}}</span>
			</button>
			<div class="skip" routerLink="/login">{{'REGISTER_SUCCESS.LINKS.Skip' | translate}}</div>
		</div>
		<form *ngIf="!success" [formGroup]="registerForm" class="flexer" style="margin: auto" (ngSubmit)="onSubmit()">
			<ng-template [ngIf]="!success" [ngIfElse]="successSend">
				<div class="login-register-title">{{'REGISTER_FORM.TITLE.Register' | translate}}</div>
				<div class="imageUploader">
					<div class="flexer circleImgContainer">
						<img [src]='profileImgUrl'>
					</div>
					<div class="addPhoto">
						<label for="profileImg">
							<svg xmlns="http://www.w3.org/2000/svg" width="53.356" height="53.356" viewBox="0 0 53.356 53.356">
								<g id="Group_25492" data-name="Group 25492" transform="translate(-228.322 -155.5)">
									<circle id="Ellipse_3936" data-name="Ellipse 3936" cx="26.678" cy="26.678" r="26.678" transform="translate(228.322 155.5)" fill="#f28900"/>
									<g id="_711191" data-name="711191" transform="translate(239.36 120.986)">
										<g id="Group_17961" data-name="Group 17961" transform="translate(0 47.104)">
											<g id="Group_17960" data-name="Group 17960" transform="translate(0 0)">
												<path id="Path_13065" data-name="Path 13065" d="M27.539,51.456H24.275l-1.8-2.72A3.745,3.745,0,0,0,19.379,47.1H11.9a3.745,3.745,0,0,0-3.094,1.632L7,51.456H3.74A3.729,3.729,0,0,0,0,55.2V68.387a3.729,3.729,0,0,0,3.74,3.74h23.8a3.729,3.729,0,0,0,3.74-3.74V55.2A3.729,3.729,0,0,0,27.539,51.456ZM15.64,69.067a8.415,8.415,0,1,1,8.432-8.4A8.43,8.43,0,0,1,15.64,69.067ZM27.063,56.726h-1.5a1.106,1.106,0,0,1,0-2.21h1.36a1.1,1.1,0,0,1,1.156,1.054A1.076,1.076,0,0,1,27.063,56.726Z" transform="translate(0 -47.104)" fill="#f3f4f5"/>
											</g>
										</g>
										<g id="Group_17963" data-name="Group 17963" transform="translate(10.948 55.978)">
											<g id="Group_17962" data-name="Group 17962">
												<path id="Path_13066" data-name="Path 13066" d="M169.556,180.736a4.675,4.675,0,1,0,4.692,4.658A4.7,4.7,0,0,0,169.556,180.736Z" transform="translate(-164.864 -180.736)" fill="#f3f4f5"/>
											</g>
										</g>
									</g>
								</g>
							</svg>
							<input (change)="onProfileImgSelect($event.target.files)" id="profileImg" type="file" formControlName="image" placeholder="image">
						</label>
					</div>
				</div>
				<div class="input-wrap" [ngClass]="{ 'has-error': f.email.errors && (submitted || f.email.touched) }">
					<input class="register-input" type="text" formControlName="email" placeholder="{{'REGISTER_FORM.PLACEHOLDERS.Email' | translate}}">
					<span class="error" *ngIf="f.email.errors && (submitted || f.email.touched)">{{'REGISTER_FORM.ERRORS.Valid_email' | translate}}</span>
				</div>
				<div class="input-wrap"
					[ngClass]="{ 'has-error': f.new_password.errors && (submitted || f.new_password.touched) }">
					<input class="register-input" type="password" formControlName="new_password" placeholder="{{'REGISTER_FORM.PLACEHOLDERS.Password' | translate}}">
					<span class="error" *ngIf="f.new_password?.errors?.required && (submitted || f.new_password.touched)">
						{{'REGISTER_FORM.ERRORS.Enter_password' | translate}}
					</span>
					<span class="error" *ngIf="f.new_password?.errors?.minlength && (submitted || f.new_password.touched)">
						{{'REGISTER_FORM.ERRORS.Min_length' | translate}}
					</span>
				</div>
				<div class="input-wrap">
					<span style="color: red;" *ngIf="(f.new_password?.errors?.pattern && !f.new_password?.errors?.minlength) && (submitted || f.new_password.touched)">
						{{ 'REGISTER_FORM.ERRORS.Password_pattern' | translate }}
					</span>
				</div>
				<div class="input-wrap"
					[ngClass]="{ 'has-error': (f.password_repeat.errors && (submitted || f.password_repeat.touched)) || registerForm.errors?.doesMatchPassword }">
					<input class="register-input" type="password" formControlName="password_repeat" placeholder="{{'REGISTER_FORM.PLACEHOLDERS.Repeat_pass' | translate}}">
					<span class="error" *ngIf="registerForm.errors?.doesMatchPassword" translate>
						{{'REGISTER_FORM.ERRORS.Same_pass' | translate}}
					</span>
					<span class="error" *ngIf="f.password_repeat.errors?.required && (submitted || f.password_repeat.touched)"
						translate>
						{{'REGISTER_FORM.ERRORS.Enter_password' | translate}}
					</span>
				</div>
				<!-- <div class="input-wrap" [ngClass]="{ 'has-error': f.name.errors && (submitted || f.name.touched) }">
					<input class="register-input" type="text" formControlName="name" placeholder="{{'REGISTER_FORM.PLACEHOLDERS.Name' | translate}}">
					<span class="error" *ngIf="f.name.errors && (submitted || f.name.touched)">
						{{'REGISTER_FORM.ERRORS.Name_required' | translate}}

					</span>
				</div> -->
				<div class="input-wrap" [ngClass]="{ 'has-error': f.first_name.errors && (submitted || f.first_name.touched) }">
					<input class="register-input" type="text" formControlName="first_name" placeholder="{{'REGISTER_FORM.PLACEHOLDERS.First_name' | translate}}">
					<span class="error" *ngIf="f.first_name.errors && (submitted || f.first_name.touched)">
						{{'REGISTER_FORM.ERRORS.First_name_req' | translate}}
					</span>
				</div>
				<div class="input-wrap" [ngClass]="{ 'has-error': f.last_name.errors && (submitted || f.last_name.touched) }">
					<input class="register-input" type="text" formControlName="last_name" placeholder="{{'REGISTER_FORM.PLACEHOLDERS.Last_name' | translate}}">
					<span class="error" *ngIf="f.last_name.errors && (submitted || f.last_name.touched)">
						{{'REGISTER_FORM.ERRORS.Last_name_req' | translate}}
					</span>
				</div>
				<div class="input-wrap"
					[ngClass]="{ 'has-error': f.phone_number.errors && (submitted || f.phone_number.touched) }">
					<input class="register-input" type="text" formControlName="phone_number"
						[pattern]="'^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'" placeholder="{{'REGISTER_FORM.PLACEHOLDERS.Phone' | translate}}">
					<span class="error" *ngIf="f.phone_number.errors?.required && (submitted || f.phone_number.touched)">
						{{'REGISTER_FORM.ERRORS.Phone_required' | translate}}
					</span>
					<span class="error" *ngIf="f.phone_number.errors?.pattern && (submitted || f.phone_number.touched)">
						{{'REGISTER_FORM.ERRORS.Phone_invalid' | translate}}
					</span>
				</div>
				<!-- <div class="input-wrap" [ngClass]="{ 'has-error': f.age.errors && (submitted || f.age.touched) }">
					<input class="register-input" pattern="^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$" type="text"
						formControlName="age" placeholder="{{'REGISTER_FORM.PLACEHOLDERS.Age' | translate}}">
					<span class="error" *ngIf="f.age.errors?.required && (submitted || f.age.touched)">
						{{'REGISTER_FORM.ERRORS.Age_required' | translate}}
					</span>
					<span class="error" *ngIf="f.age.errors?.min && (submitted || f.age.touched)">
						{{'REGISTER_FORM.ERRORS.Age_range' | translate}}
					</span>
					<span class="error" *ngIf="f.age.errors?.max && (submitted || f.age.touched)">
						{{'REGISTER_FORM.ERRORS.Age_range' | translate}}
					</span>
					<span class="error" *ngIf="f.age.errors?.pattern && (submitted || f.age.touched)">
						{{'REGISTER_FORM.ERRORS.Age_range' | translate}}
					</span>
				</div> -->
				<div class="input-wrap" [ngClass]="{ 'has-error': f.weight.errors && (submitted || f.weight.touched) }">
					<input class="register-input" type="number" min="1" max="300"
						formControlName="weight" placeholder="{{'REGISTER_FORM.PLACEHOLDERS.Weight' | translate}}">
					<span class="error" *ngIf="f.weight.errors && (submitted || f.weight.touched)">
						{{'REGISTER_FORM.ERRORS.Weight_req' | translate}}
					</span>
				</div>
				<div class="input-wrap" [ngClass]="{ 'has-error': f.height.errors && (submitted || f.height.touched) }">
					<input class="register-input" type="number" min="1" max="300"
						formControlName="height" placeholder="{{'REGISTER_FORM.PLACEHOLDERS.Height' | translate}}">
					<span class="error" *ngIf="f.height.errors && (submitted || f.height.touched)">
						{{'REGISTER_FORM.ERRORS.Height_req' | translate}}
					</span>
				</div>
				<div class="input-wrap" [ngClass]="{ 'has-error': f.birth_date.errors && (submitted || f.birth_date.touched)}">
					<label for="birth_date">{{'REGISTER_FORM.LABELS.Birth_date' | translate}}</label>
					<input class="register-input" type="date" formControlName="birth_date" id="birth_date" max="3000-01-01">
					<span class="error" *ngIf="f.birth_date.errors?.required && (submitted || f.birth_date.touched)">
						{{'REGISTER_FORM.ERRORS.Birth_date_req' | translate}}
					</span>
				</div>
				<div class="register-genders">
					<input autocomplete="off" class="gender" type="radio" formControlName="gender" value="male" id="gender-male">
						<label for="gender-male">	{{'REGISTER_FORM.LABELS.Gender_male' | translate}}</label>

					<input autocomplete="off" class="gender" type="radio" formControlName="gender" value="female"
						id="gender-female">
					<label for="gender-female">	{{'REGISTER_FORM.LABELS.Gender_female' | translate}}</label>
					<p class="form_error_msg" *ngIf="f.gender.errors && (submitted || f.gender.touched)">
						{{'REGISTER_FORM.ERRORS.Gender_required' | translate}}
					</p>
				</div>
				<button [disabled]="loading" class="login-register-button" type="submit">
					<span *ngIf="!loading">{{'BUTTONS.Register' | translate}}</span>
					<span *ngIf="loading">Loading ...</span>
				</button>
				<div class="register-bottom" *ngIf="register == 'web'">
					<span class="register-bottom-text">{{'REGISTER_FORM.MISC.Have_account' | translate}}</span>
					<a routerLink="/login" class="register-bottom-login">{{'REGISTER_FORM.LINKS.Login_here' | translate}}</a>
				</div>
				<div class="terms-and-cond-text">
					<label (click)="readTerms()">
						<input type="checkbox" name="terms_and_conditions" formControlName="terms_and_conditions" id="terms-checkbox" (click)="$event.preventDefault()"
						[attr.disabled]="agreed ? true : null"
						[checked]="agreed">
						{{'REGISTER_FORM.MISC.Term_text'| translate}}
						<span style="color: #31ADFF;">{{'REGISTER_FORM.LINKS.Terms_conditions' | translate}}</span>
					</label>
					<terms-and-conditions [show]="showTermsAndConditions" (agreed)="agreeTerms($event)"></terms-and-conditions>
					<!-- <a routerLink="/static-page/terms-and-conditions" class="register-bottom-login">{{'REGISTER_FORM.LINKS.Terms_conditions' | translate}}</a> -->
					<p class="form_error_msg" style="color:red" *ngIf="!agreed && submitted">
						Please read our Terms and Conditions
					</p>
				</div>
			</ng-template>
			<ng-template #successSend>
				<div class="login-register-title">{{'REGISTER_FORM.MISC.CONGRAT'| translate}}</div>
				<div class="submessage-text">
					<span class="submessage-text">
						{{'REGISTER_FORM.MISC.Success_register'| translate}}
					</span>
				</div>
			</ng-template>
		</form>
	</div>
</div>
<div class="dialog-wrapper">
    <div class="dialog-header">
        <h2>
            {{ 'LAYOUT.PROFILE.EMERGENCY_CONTACTS.LABELS.Add_emergency_contact' | translate }}
        </h2>
        <div class="close" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>

    <div class="dialog-body">
        <form novalidate (ngSubmit)="onSubmit(f)" #f="ngForm">

            <div class="flex gap05 align-items-center mb1">
                <label for="name">{{ 'LAYOUT.PROFILE.EMERGENCY_CONTACTS.LABELS.Emergency_contact_name' | translate}}</label>
                <input type="text" name="name" required [(ngModel)]="contactName" #name="ngModel">
            </div>

            <div class="flex align-items-center gap05 mb1">
                <label for="phone">{{ 'LAYOUT.PROFILE.EMERGENCY_CONTACTS.LABELS.Emergency_contact_phone' | translate}}</label>
                <input type="text" name="phone" required [(ngModel)]="contactPhone" #phone="ngModel">
            </div>


            <button type="submit" class="blue-button">
                {{ !isEdit ? ('LAYOUT.PROFILE.EMERGENCY_CONTACTS.BUTTONS.Save' | translate) : ('LAYOUT.PROFILE.EMERGENCY_CONTACTS.BUTTONS.Edit' | translate) }}
            </button>
        </form>
    </div>

    <div class="dialog-footer"></div>
</div>
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as SimplePeer from 'simple-peer';
import { PeerService } from '../../services/peer.service';
import { CommService, WebsocketStates } from '../../services/comm.service';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { SocketIoService } from 'src/app/services/socket-io.service';
import { getNameInitials, getAvatarColor } from '../../constants/utils';
import { Subscription } from 'rxjs';
import { ChatService, VideoCallStatus } from '../../services/chat.service';

@Component({
  selector: 'app-video-calls',
  templateUrl: './video-calls.component.html',
  styleUrls: ['./video-calls.component.scss']
})
export class VideoCallsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('localVideo', { static: true }) localVideo!: ElementRef;
  @ViewChild('remoteVideos', { read: ElementRef, static: true }) remoteVideos!: ElementRef;
  @ViewChild('dialTone') dialTone!: ElementRef;
  @ViewChild('hangupTone') hangupTone!: ElementRef;
  @ViewChild('busyTone') busyTone!: ElementRef;

  public isMuted: boolean = false;
  public stopCam: boolean = false;
  public connecting: boolean = true;
  public collapse: boolean = false;

  public socketIOService!: SocketIoService;
  public peer: SimplePeer.Instance | undefined;
  public initiator: boolean = false;
  public localStream: MediaStream | undefined;
  public roomId: any;
  public incomingCall: boolean = false;
  public callAccepted: boolean = false;
  public remoteVideoElements: Map<any, any> = new Map<any, any>();
  public userId: any;
  public initials: string;
  public name: string;
  public avatarBgColor: string;
  public websocketStates: any = WebsocketStates;
  public waitForAnswerTimeout: any;
  public callParticipants: Map<any, { name: string, initials: string, avatarBgColor: string }> = new Map();
  private changeMediaTimeout: any;
  private subscriptions: Subscription = new Subscription();
  private closingCall: boolean = false;

  constructor(
    public renderer: Renderer2,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VideoCallsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      initiator: boolean,
      roomId: any,
      userId: any,
      socketService: any,
      name: any,
      callerName: any,
      callerHash: any,
      scheduledCall: any
    },
    private commService: CommService,
    private peerService: PeerService,
    private chatService: ChatService
  ) {
    this.userId = this.data.userId;
    this.name = this.data.name;
    this.initials = getNameInitials(this.name);
    this.avatarBgColor = getAvatarColor();
    this.roomId = this.data.roomId;
    this.initiator = this.data.initiator;
    this.socketIOService = this.data.socketService;
  }

  ngOnInit(): void {
    this.initPeerAndComm();
  }

  ngAfterViewInit(): void {
    this.checkWebsocketState();
  }

  ngOnDestroy(): void {
    this.pauseDialTone();
    clearTimeout(this.waitForAnswerTimeout);
    this.subscriptions.unsubscribe();
  }

  initPeerAndComm() {
    this.commService.wsConnect();
    this.peerService.setSocketIO(this.socketIOService);
    this.peerService.setComm(this.commService);
    this.peerService.setUserId(this.userId);
    this.peerService.setInitiator(this.data.initiator);
    this.peerService.setAddMediaFn(this.addMedia.bind(this));
    this.peerService.setRemoveMediaFn(this.removeRemoteVideo.bind(this));
    this.peerService.setChatRoom(this.roomId);
    this.checkRoomParticipants();
    let sub = this.peerService.connecting$.subscribe((status: boolean) => {
      this.connecting = status;
    });
    this.subscriptions.add(sub);
    this.socketIOService.listenForCallEnd((roomId: any) => {
      if (roomId && roomId == this.roomId) {
        this.onCallEnded();
      }
    });
  }

  checkWebsocketState() {
    let interval = setInterval(
      () => {
        let state = this.commService.ws.readyState;

        console.log('checking websocket state', this.websocketStates[state]);
        if (this.websocketStates[state] == 'OPEN') {
          console.log('websocket is open', this.websocketStates[state]);
          if (this.data.initiator) {
            this.initiateCall();
          } else {
            this.acceptCall();
          }
          clearInterval(interval);
        }

        if (this.websocketStates[state] == 'CLOSING' || this.websocketStates[state] == 'CLOSED') {
          // handle websocket connection error
          console.log('websocket closing/closed', this.websocketStates[state]);
          this.displayError('INFO_DIALOG.TITLES.Websocket_closed', 'INFO_DIALOG.MESSAGES.Websocket_closed')
          clearInterval(interval);
        }
      }, 500
    )
  }

  // For testing purposes, initiate a call manually
  initiateCall() {
    this.listenForDisconnects();
    this.peerService.preparePeer(true, true);
    // this.socketIOService.listenForCallRejection((roomId: any, userId: any, username: any) => {
    //   if (this.roomId != roomId || this.callAccepted) {
    //     return;
    //   }
    //   console.log('call rejected');
    //   this.closeNotification();
    //   this.closeCall();
    //   this.pauseDialTone();
    //   clearTimeout(this.waitForAnswerTimeout);
    // });

    // this.waitForAnswerTimeout = setTimeout(() => {
    //   this.pauseDialTone();
    //   clearTimeout(this.waitForAnswerTimeout);
    //   if (!this.callAccepted) {
    //     this.playBusyTone();
    //     let timeout = setTimeout(() => {
    //       clearTimeout(timeout);
    //       this.closeCall();
    //     }, 1000);
    //   }
    //   this.displayError('INFO_DIALOG.TITLES.Call_no_answer', 'INFO_DIALOG.MESSAGES.Call_no_answer');
    // }, 60 * 1000);

    this.waitForAnswer();
    this.playDialTone();
  }

  waitForAnswer() {
    this.socketIOService.receiveCallAnswer((roomId: any, userHash: any, userId: any, username: any) => {
      if (this.roomId != roomId) {
        return;
      }
      
      console.log('someone accepted call', userHash);
      this.pauseDialTone();
      this.callAccepted = true;
      this.changeMediaTimeout = setTimeout(() => {
        this.peerService.changeMedia();
        clearTimeout(this.changeMediaTimeout);
      }, 1000);
    });
  }

  // For testing purposes, accept a call manually
  acceptCall() {
    let isScheduledCall = this.data.scheduledCall || false;
    this.peerService.preparePeer(true, false, isScheduledCall);
    this.callAccepted = true;
    console.log('caller name', this.data.callerName);
    this.listenForDisconnects();
    // this.peerService.changeMedia();
    this.changeMediaTimeout = setTimeout(() => {
      clearTimeout(this.changeMediaTimeout);
      this.peerService.changeMedia();
    }, 1000);
  }

  toggleMute() {
    if (!this.localVideo.nativeElement.srcObject) {
      return;
    }
    this.isMuted = !this.isMuted;
    this.localVideo.nativeElement.srcObject.getAudioTracks().forEach((track: any) => {
      track.enabled = !this.isMuted;
    });
  }

  toggleCam() {
    if (!this.localVideo.nativeElement.srcObject) {
      return;
    }
    this.stopCam = !this.stopCam;
    this.localVideo.nativeElement.srcObject.getVideoTracks().forEach((track: any) => {
      track.enabled = !this.stopCam;
    });
  }

  toggleCollapse() {
    this.collapse = !this.collapse;
  }

  addMedia(stream: MediaStream, peerHash?: string) {
    if (this.remoteVideoElements.has(peerHash)) {
      return;
    }

    if (this.peerService.userHash == peerHash) {
      this.localVideo.nativeElement.srcObject = stream;
      return;
    }
    console.log('adding remote video', stream);
    // this.checkVideoTrackState(stream, peerHash);
    const videoContainer = this.renderer.createElement('div');
    this.renderer.addClass(videoContainer, 'video-container');
    const video = this.renderer.createElement('video');
    this.renderer.addClass(video, 'video-element');
    video.srcObject = stream;
    video.autoplay = true;
    // video.muted = true;

    if (peerHash) {
      video.setAttribute('data-peerHash', peerHash);
    }

    video.play()
      .catch((error) => {
        console.error('Error playing remote stream:', error);
      });

    this.renderer.appendChild(videoContainer, video);

    const avatarContainer = this.renderer.createElement('div');
    this.renderer.addClass(avatarContainer, 'avatar-container');

    const avatarElement = this.renderer.createElement('div');
    let participant = this.callParticipants.get(peerHash);
    this.renderer.setStyle(avatarElement, 'display', 'none');
    this.renderer.addClass(avatarElement, 'avatar');
    this.renderer.setAttribute(avatarElement, 'id', `avatar-${peerHash}`);
    if (participant) {
      this.renderer.setStyle(avatarElement, 'background-color', participant.avatarBgColor);
      let text = this.renderer.createText(participant.initials);
      this.renderer.appendChild(avatarElement, text);
    }

    this.renderer.appendChild(avatarContainer, avatarElement);
    this.renderer.appendChild(videoContainer, avatarContainer);

    this.renderer.listen(video, 'pause', () => {
      console.log('user paused video');
      this.renderer.setStyle(avatarElement, 'display', 'block');
    });

    this.renderer.listen(video, 'play', () => {
      this.renderer.setStyle(avatarElement, 'display', 'none');
    });

    this.remoteVideoElements.set(peerHash, videoContainer);
    this.remoteVideos.nativeElement.appendChild(videoContainer);
  }

  removeRemoteVideo(peerHash: any) {
    console.log('remove remote video for peer', peerHash)
    let videoEl: ElementRef = this.remoteVideoElements.get(peerHash) as ElementRef;
    this.remoteVideoElements.delete(peerHash);
    if (videoEl) {
      this.remoteVideos.nativeElement.removeChild(videoEl);
    }

    //remove from call participants
    let participant = this.callParticipants.get(peerHash);
    if (participant) {
      this.callParticipants.delete(peerHash);
    }
  }

  checkVideoTrackState(stream: MediaStream, peerHash: string) {
    if (!stream || !peerHash) {
      return;
    }
    alert('set state');
    let avatarElement = document.getElementById(`avatar-${peerHash}`);
    const videoTracks = stream.getVideoTracks();
    if (videoTracks.length > 0) {
      const videoTrack = videoTracks[0];

      // Event listener for track 'ended'
      videoTrack.addEventListener('ended', () => {
        alert('ended');
        this.showAvatar(avatarElement);
      });

      // Event listener for track 'mute' and 'unmute'
      // videoTrack.addEventListener('mute', () => {
      //   this.showAvatar();
      // });
      // videoTrack.addEventListener('unmute', () => {
      //   this.hideAvatar();
      // });

      // Initial state check
      if (videoTrack.readyState === 'ended' || videoTrack.muted) {
        this.showAvatar(avatarElement);
      } else {
        this.hideAvatar(avatarElement);
      }
    } else {
      this.showAvatar(avatarElement);
    }
  }

  showAvatar(avatarElement: any) {
    if (!avatarElement) {
      return;
    }
    alert(`show avatar ${avatarElement.id}`)
    avatarElement.style.display = 'block';
    avatarElement.innerHTML = 'test';
  }

  hideAvatar(avatarElement: any) {
    alert('hide avatar');
    avatarElement.style.display = 'none';
  }

  showParticipantsNames() {
    let arrParticipants = Array.from(this.callParticipants.values());
    let arrNames = arrParticipants.map((el: any) => el.name);
    return arrNames.join(', ');
  }

  listenForDisconnects() {
    this.socketIOService.listenForPeerDisconnects((roomId: any, peerHash: any, userId: any, username: any) => {
      if (this.roomId != roomId) {
        return;
      }
      console.log('someone disconnected', peerHash);
      if (this.peerService.userHash != peerHash) {
        this.removeRemoteVideo(peerHash);
      }
      console.log('remoteVideos', this.remoteVideoElements);
      if (this.remoteVideoElements.size == 0) {
        this.closeCall();
      }
    });

  }

  checkRoomParticipants() {
    this.socketIOService.roomParticipants((roomId: any, participants: any[]) => {
      if (this.roomId != roomId) {
        return;
      }
      console.log('participants', participants);
      for (let participant of participants) {
        let peerHash = participant.peerHash;
        let name = participant.userNames;
        let isAdded = this.callParticipants.get(peerHash);
        if (!isAdded) {
          let initials = getNameInitials(name);
          let avatarBgColor = getAvatarColor();
          this.callParticipants.set(peerHash, { name, initials, avatarBgColor });
        }
      }
    });
  }

  closeNotification() {
    this.socketIOService.disconnectPeer(this.roomId);
  }

  closeCall() {
    if (this.closingCall) {
      return;
    }

    this.closingCall = true;
    for (let key of this.remoteVideoElements.keys()) {
      let videoEl = this.remoteVideoElements.get(key);
      this.remoteVideos.nativeElement.removeChild(videoEl);
      this.remoteVideoElements.delete(key);
    }

    this.localVideo.nativeElement.srcObject = null;
    this.callAccepted = false;
    this.incomingCall = false;
    this.peerService.closeStream();
    clearTimeout(this.changeMediaTimeout);
    this.commService.close();
    if (this.remoteVideoElements.size == 0) {
      // this.socketIOService.endCall(this.roomId);
      this.chatService.setCallStatus(VideoCallStatus.ended);
    }
    this.playHangupTone();
    // close call window/dialog
    let timeout = setTimeout(() => {
      clearTimeout(timeout);
      this.dialogRef.close();
    }, 500);
    console.log('ending call');

  }

  displayError(title: string, message: string) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: { title, message },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.closeCall();
    });
  }

  playDialTone() {
    if (!this.dialTone) {
      return;
    }

    this.dialTone.nativeElement.play();
  }

  pauseDialTone() {
    if (!this.dialTone) {
      return;
    }

    this.dialTone.nativeElement.pause();
  }

  playHangupTone() {
    if (!this.hangupTone) {
      return;
    }

    this.hangupTone.nativeElement.play();
  }

  playBusyTone() {
    if (!this.busyTone) {
      return;
    }

    this.busyTone.nativeElement.play();
  }

  onCallEnded() {
    this.closeCall();
  }

}

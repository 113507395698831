import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { ChatHistoryCriteria, ChatMessage } from '../chat/services/chat.service';
import { BehaviorSubject, Observable } from 'rxjs';

export enum SocketStates {
  'closed' = 0,
  'open' = 1,
  'closedManually' = 2,
  'reconnect' = 3
}

@Injectable({
  providedIn: 'root'
})
export class SocketIoService {
  private socket: Socket;
  private url: any = environment.socketIO;
  private userInfo: any;
  private subscribeData = {
    DeviceIds: [],
    Channels: ['vital-params', 'last-vital-params']
  }
  private socketConnected: boolean = false;
  private socketState: BehaviorSubject<SocketStates> = new BehaviorSubject(SocketStates.closed);
  public socketState$: Observable<any> = this.socketState.asObservable();

  constructor(private localStorage: LocalStorageService) {}

  initializeConnection() {
    if (this.socketConnected) {
      return;
    }
    this.userInfo = this.localStorage.retrieve('loggedUser');
    this.socket = io(this.url, {
      query: {
        WebToken: this.userInfo?.userToken?.token,
        ClientType: 'ChatWeb'
      },
      transports: ['websocket']
    });

    this.socket.on('connect', this.onConnect.bind(this));
    this.socket.on('disconnect', this.onDisconnect.bind(this));
  }

  isConnected() {
    return this.socket?.connected;
  }

  onConnect(event: any) {
    this.socketConnected = true;
    this.setSocketState(SocketStates.open);
    console.log('Socket.IO connection established');
  }

  setSocketState(state: SocketStates) {
    this.socketState.next(state);
  }

  onDisconnect(reason: any) {
    if (this.socketConnected) {
      console.log(`Socket.IO connection closed: ${reason}`);
      this.socketConnected = false;
      this.setSocketState(SocketStates.closed);
      this.socket.removeAllListeners();
      // Reconnect after a delay 
      let timeout = setTimeout(() => {
        if (this.socketConnected) {
          clearInterval(timeout);
          return;
        } 
        this.initializeConnection()
      }, 2000);
    }
  }

  subscribeForVitalsData() {
    this.socket.emit('subscribe', this.subscribeData, function (callback) {
      console.log('subscribe', callback);
    });
  }

  unsubscribeForVitalsData() {
    this.socket.emit('unsubscribeChannels', this.subscribeData, function (callback) {
      console.log('unsubscribe', callback);
    });
  }

  getVitals(callback: (message: string) => void) {
    this.socket.on('vital-params', callback);
  }

  getLastVitals(callback: (message: string) => void) {
    this.socket.on('last-vital-params', callback);
  }

  sendChatMessage(text: any, callback: (response: any) => void) {
    this.socket.emit('patient-message', text);
  }

  receiveChatMessage(callback: (message: string) => void) {
    this.socket.on('patient-message', callback);
  }

  stopListeningForChatMessages() {
    this.socket.off('patient-message');
  }

  joinChatRoom(room: string) {
    this.socket.emit('join-room', [room], function (callback: any) {
      console.log('subscribe', callback);
    });
  }

  leaveChatRoom(room: string) {
    this.socket.emit('leave-room', [room], function (callback: any) {
      console.log('left room', callback);
    });
  }

  getUnreadMessages(room: string, callback: (response: any) => void) {
    this.socket.emit('patient-unseen-messages', { room }, callback);
  }

  setMessagesAsSeen(messages: ChatMessage[]) {
    this.socket.emit('patient-messages-seen', { messages });
  }

  getChatHistory(criteria: ChatHistoryCriteria, callback: (response: any) => void) {
    this.socket.emit('find-patient-history', criteria, callback);
  }

  sendCallOffer(roomId: any, callback: (data: any) => void) {
    this.socket.emit('call-offer', roomId, callback);
  }


  listenForIncomingCalls(callback: (roomId: any, peerHash: any, userId: any, username: any) => void) {
    this.socket.on('receive-offer', callback);
  }

  answerCallOffer(roomId: any, callback: (data: any) => void) {
    this.socket.emit('call-answer', roomId, callback);
  }

  receiveCallAnswer(callback: (roomId: any, userHash: any, userId: any, username: any) => void) {
    this.socket.on('receive-answer', callback);
  }

  rejectCall(roomId: any) {
    this.socket.emit('call-rejection', roomId);
  }

  listenForCallRejection(callback: (roomId: any, userId: any, username: any) => void) {
    this.socket.on('call-rejected', callback);
  }

  // when call is accepted from other device
  listenForCallAccepted(callback: (roomId: any) => void) {
    this.socket.on('call-accepted', callback);
  }

  checkForActiveCall(roomId: any, callback: (response: any) => void) {
    this.socket.emit('check-for-active-call', roomId, callback);
  }

  disconnectPeer(roomId: any) {
    this.socket.emit('disconnect-peer', roomId);
  }

  listenForPeerDisconnects(callback: (roomId: any, userHash: any, userId: any, username: any) => void) {
    this.socket.on('peer-disconnected', callback);
  }

  endCall(roomId: any) {
    this.socket.emit('end-call', roomId);
  }

  listenForCallEnd(callback: (roomId: any) => void) {
    this.socket.on('call-ended', callback);
  }

  joinOngoingCall(roomId: any, callback: (roomId: any, peerHash: any, userId: any, username: any) => void) {
    this.socket.emit('get-user-hash', roomId, callback);
  }

  roomParticipants(callback: (roomId: any, participants: any[]) => void) {
    this.socket.on('room-participants', callback);
  }

  // Close the socket connection
  closeConnection() {
    this.unsubscribeForVitalsData();
    this.socketState.next(SocketStates.closedManually);
    this.socket.disconnect();
    this.socketConnected = false;
  }

  removeEventListeners(event: string) {
    this.socket.removeAllListeners(event);
  }
}
